import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { Suspense, useMemo, type FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useAuth } from '@volvo/vce-uikit';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import { UnassignedLoadTickets } from '../../../federations/unassignedLoadTickets';
import { getApolloClient } from '../../../gql/apollo-client';
import mapI18n from '../../../i18n';
import { theme } from '../../../theme';
import { useApp } from '../../page-layout/PageLayout';

export const UnassignedLoadTicketsPage: FC = () => {
  const { siteId } = useApp();
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={mapI18n}>
        <Suspense fallback={<CenteredSpinner />} />
        <ThemeProvider theme={theme}>
          <UnassignedLoadTickets siteId={siteId} companyId="278c2b55-21dd-4128-832e-687b06800821" />
        </ThemeProvider>
      </I18nextProvider>
    </ApolloProvider>
  );
};
