import axios from 'axios';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { KeyedMutator } from 'swr';
import useSWRMutation from 'swr/mutation';
import { ButtonVariant, toast } from '@volvo/vce-uikit';
import { StyledButton } from './styles';

const url = import.meta.env.VITE_API_ENDPOINT_SITESIM + '/site-machine-simulation';

type Props = {
  refetch: KeyedMutator<string>;
};

const deleteWithToken = (url: string, token: string) =>
  axios
    .delete(url, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);

export const Delete: FC<Props> = ({ refetch }) => {
  const { t } = useTranslation();

  const { trigger, isMutating } = useSWRMutation(
    [url, `Bearer ${localStorage.getItem('token')}`],
    ([url, token]) =>
      deleteWithToken(url, token).then((res) => {
        toast.success(res);
        refetch();
      }),
  );

  return (
    <StyledButton variant={ButtonVariant.Primary} disabled={isMutating} onClick={() => trigger()}>
      {t('Stop simulation')}
    </StyledButton>
  );
};
