import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  AuthGuard,
  AuthProvider,
  ErrorBoundary,
  InternalError,
  LoginPage,
  LoginType,
  themes,
  UikitApp,
} from '@volvo/vce-uikit';
import i18n from '../i18n';
import { PageLayout } from './page-layout/PageLayout';
import { AdminPage } from './pages/admin/Admin';
import { ProductionDataPage } from './pages/production-data/ProductionData';
import { SiteMapPage } from './pages/site-map/SiteMap';
import { UnassignedLoadTicketsPage } from './pages/unassigned-load-tickets/UnassignedLoadTickets';

// import '../../wdyr';

loadDevMessages();
loadErrorMessages();

function App() {
  return (
    <ErrorBoundary errorContent={<InternalError />}>
      <AuthProvider basePath={import.meta.env.VITE_API_URL_AUTH}>
        <PageLayout />
      </AuthProvider>
    </ErrorBoundary>
  );
}

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/login',
        element: <LoginPage loginTypes={[LoginType.Credentials, LoginType.OpenIdConnect]} />,
      },
      {
        path: '/unassigned',
        element: (
          <AuthGuard>
            <UnassignedLoadTicketsPage />
          </AuthGuard>
        ),
      },
      {
        path: '/production-data',
        element: (
          <AuthGuard>
            <ProductionDataPage />
          </AuthGuard>
        ),
      },
      {
        path: '/admin',
        element: (
          <AuthGuard>
            <AdminPage />
          </AuthGuard>
        ),
      },
      {
        path: '/*',
        element: (
          <AuthGuard>
            <SiteMapPage baseUrl="/map" />
          </AuthGuard>
        ),
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <UikitApp theme={themes['volvo-light']} i18n={i18n}>
      <RouterProvider router={router} />
    </UikitApp>
  </StrictMode>,
);
