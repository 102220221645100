import { ThemeProvider } from '@emotion/react';
import { Suspense, type FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import { ProductionData } from '../../../federations/production-data';
import mapI18n from '../../../i18n';
import { theme } from '../../../theme';
import { useApp } from '../../page-layout/PageLayout';

export const ProductionDataPage: FC = () => {
  const { siteId } = useApp();

  return (
    <I18nextProvider i18n={mapI18n}>
      <Suspense fallback={<CenteredSpinner />} />
      <ThemeProvider theme={theme}>
        <ProductionData siteId={siteId} />
      </ThemeProvider>
    </I18nextProvider>
  );
};
